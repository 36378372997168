import React from "react";
import styles from "./pricing-cards.module.scss";
import classnames from "classnames";
import SbEditable from "storyblok-react";
import { PricingCardsCondensedStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: PricingCardsCondensedStoryblok;
}

const PricingCardsCondensed = (props: Props) => {
  const {
    blok: {
      core_title,
      core_copy,
      core_price,
      core_per,
      premium_title,
      premium_copy,
      premium_price,
      premium_per,
      elite_title,
      elite_copy,
      elite_price,
      elite_per,
    },
  } = props;
  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <div className={styles.pricing}>
        <PricingCardCondensed
          type={"elite"}
          title={elite_title}
          copy={elite_copy}
          price={elite_price}
          per={elite_per}
        />
        <PricingCardCondensed
          type={"core"}
          title={core_title}
          copy={core_copy}
          price={core_price}
          per={core_per}
        />
        <PricingCardCondensed
          type={"premium"}
          title={premium_title}
          copy={premium_copy}
          price={premium_price}
          per={premium_per}
        />
      </div>
    </SbEditable>
  );
};

interface PricingCardCondensedProps {
  type: "core" | "premium" | "elite";
  title?: string;
  copy?: string;
  price?: string;
  per?: string;
  className?: string;
}

const PricingCardCondensed = (props: PricingCardCondensedProps) => {
  const {
    type,
    title = "Title",
    copy = "copy",
    price = "on request",
    per,
    className,
  } = props;
  const classes = classnames(styles.card, styles[type], className);

  return (
    <div className={classes}>
      <h3 className={styles.title}>{title}</h3>
      <hr className={styles.cardDivider} />
      <h6 className={styles.copy}>{copy}</h6>
      <hr className={styles.cardDivider} />
      <p className={styles.price}>{price}</p>
      <p className={styles.per}>{per}</p>
    </div>
  );
};

export default PricingCardsCondensed;
